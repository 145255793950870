<template>
  <b-modal
      id="add-other-price-modal"
      :title="editData.id === undefined ? 'Add Fee' : 'Edit Fee'"
      size="lg"
      ref="add-other-price-modal"
      v-bind:hide-footer="true"
  >
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="purchase_name">Purchase Name</label>
          <select class="form-control" name="purchase_name" id="purchase_name" v-model="form.purchase_name">
            <option value="">Select</option>
            <option :value="id" v-for="(purchase, id) in purchases" :key="'purchase'+id">{{ purchase }}</option>
          </select>
          <div class="text-sm text-danger">{{ errors.purchase_name }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="amount">Fee ($)</label>
          <input type="number" name="amount" id="amount" v-model="form.amount" class="form-control"/>
          <div class="text-sm text-danger">{{ errors.amount }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="form-label">Start Date</label>
          <div class="input-group birthdate">
            <div class="birthdaypicker">
              <date-pick
                  v-model="form.start_date"
                  :format="'MM/DD/YYYY'"
                  :displayFormat="'MM/DD/YYYY'"
                  v-mask="'##/##/####'"
                  :parseDate="parseDatePick"
              ></date-pick>
            </div>
          </div>
          <input id="start_date" type="hidden" class="form-control" :value="start_date_1">
          <div class="text-sm text-danger">{{ errors.start_date }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="form-label">End Date</label>
          <div class="input-group birthdate">
            <div class="birthdaypicker">
              <date-pick
                  v-model="form.end_date"
                  :format="'MM/DD/YYYY'"
                  :displayFormat="'MM/DD/YYYY'"
                  v-mask="'##/##/####'"
                  :parseDate="parseDatePick"
              ></date-pick>
            </div>
          </div>
          <input id="end_date" type="hidden" class="form-control" :value="end_date_1">
          <div class="text-sm text-danger">{{ errors.end_date }}</div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="status">Status</label>
          <select class="form-control" name="status" id="status" v-model="form.status">
            <option value="">Select</option>
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
          <div class="text-sm text-danger">{{ errors.status }}</div>
        </div>
      </div>
      <div class="col-md-12 modal-searchbtn text-center">
        <div class="form-group">
          <button type="submit" class="btn btn-primary btn-blue" @click="handleOk" :disabled="loading">
            <i class="fa fa-circle-notch fa-spin fa-lg" v-if="loading"></i>
            <span v-else>Submit</span>
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios';
import DatePick from "vue-date-pick";
import moment from 'moment';

export default {
  name: "AddOtherPrice",
  components: {DatePick},
  props: ['editData'],
  data() {
    return {
      loading: false,
      form: {
        id: "",
        amount: "",
        start_date: "",
        end_date: "",
        purchase_name: "",
        status: ""
      },
      purchases: [],
      errors: {}
    };
  },
  methods: {
    validate() {
      this.errors = {};
      if (this.form.purchase_name === "") {
        this.errors.purchase_name = "Purchase Name is required";
      }
      if (this.form.amount === "") {
        this.errors.amount = "Amount is required";
      } else if(isNaN(this.form.amount)){
        this.errors.amount = "Amount should be a number";
      } else if(this.form.amount <= 0){
        this.errors.amount = "Invalid Amount";
      }
      if (this.form.start_date === "") {
        this.errors.start_date = "Start Date is required";
      }
      if (this.form.end_date === "") {
        this.errors.end_date = "End Date is required";
      }
      if (this.form.status === "") {
        this.errors.status = "Status is required";
      }
      return Object.keys(this.errors).length === 0;
    },
    handleOk() {
      if (this.validate()) {
        let formData = {
          purchase_name: this.form.purchase_name,
          amount: this.form.amount,
          start_date: this.form.start_date,
          end_date: this.form.end_date,
          status: this.form.status
        };
        this.loading = true;
        if (this.form.id === "") {
          axios.post(this.basePath + "api/admin/other-pricing/store", formData, {headers: this.adminHeaders})
              .then(response => {
                this.$refs['add-other-price-modal'].hide();
                this.$emit('pricingAdded', response);
                this.loading = false;
              })
              .catch(err => {
                console.log(err);
                this.loading = false;
              });
        } else {
          axios.put(this.basePath + "api/admin/other-pricing/"+this.form.id+"/update", formData, { headers: this.adminHeaders })
              .then(response => {
                this.$refs['add-other-price-modal'].hide();
                this.$emit('pricingAdded', response);
                this.loading = false;
              })
              .catch(err => {
                console.log(err);
                this.loading = false;
              });
        }
      }
    },
    getPrice(id) {
      axios.get(this.basePath + "api/admin/other-pricing/" + id, {headers: this.adminHeaders})
          .then(response => {
            this.form.id = response.data.data.id;
            this.form.purchase_name = response.data.data.purchase_name;
            this.form.amount = response.data.data.amount;
            this.form.start_date = response.data.data.formatted_start_date;
            this.form.end_date = response.data.data.formatted_end_date;
            this.form.status = response.data.data.status;
          })
          .catch(err => {
            console.log(err);
          })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      this.form.id = "";
      this.form.amount = "";
      this.form.start_date = "";
      this.form.end_date = "";
      this.form.status = "";
      axios.get(this.basePath+"api/admin/other-pricing/purchases-list", { headers: this.adminHeaders })
          .then(response => {
            this.purchases = response.data.data;
          })
          .catch(err => console.log(err));
    });
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if(this.editData.id !== undefined)
        this.getPrice(this.editData.id);
    })
  },
  computed: {
    start_date_1: function () {
      return this.start_date;
    },
    end_date_1: function () {
      return this.end_date;
    }
  }
}
</script>

<style scoped>

</style>